.dropbox {
  margin-top: 20px;
}

.text-pooler-blue {
  color: #2c1dff;
}

.dropzone-component-upload {
  border: 1px solid #cbd1ec;
  border-style: dashed;
  max-height: 250px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

#progress {
  max-width: 360px;
  width: 100%;
}

@media (min-width: 999px) {
  #progress {
    min-width: 489px;
  }
}
