
.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  z-index: 999; /* Lower z-index than modal to ensure it's below the modal */
}

.show-overlay {
  display: block;
}
.modal-container {
  display: none;
  position: fixed;
  bottom: -40px;
  left: 0;
  width: 100%;
  background: #fff;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.show-modal {
  display: block;
  transform: translateY(0);
  border-radius: 2rem;
}