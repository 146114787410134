.form-control.--bor-pri,
.form-check-input.--bor-pri,
.--bor-pri {
  border: 1px solid #e7eaf3b3 !important;
  border-color: #e7eaf3b3 !important;
}

.summary p,
.pin p {
  font-size: 14px;
}

.summary h4 {
  font-size: 32px;
}

.summary-body:last-child {
  padding-bottom: 0px !important;
}

.summary-body > div:not(:last-child) {
  margin-bottom: 25px;
}

.summary-body span {
  font-size: 15px;
  color: #8f9bb2;
}

.summary-body strong {
  font-size: 15px;
  color: #000000;
}

div.summary-body hr {
  margin-top: 5px !important;
  /* margin-bottom: 2px !important; */
}

div.account hr {
  margin-top: 5px !important;
  margin-bottom: 2px !important;
}

.pincode-input-container {
  padding: 10px;
  width: 75%;
  justify-content: space-between;
  display: flex;
}

input.form-control.is-invalid,
.was-validated {
  border-color: #ed4c78 !important;
}

.reset-title {
  font-family: BricolageGrotesque;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #8f9bb2;
}
