.dropzone-component {
  border: 1px solid #cbd1ec;
  border-style: dashed;
  height: 150px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.click {
  font-size: 14px;
}

.file-size {
  font-size: 12px;
}

.trash {
  cursor: pointer;
  width: 10%;
  border-radius: 8px;
}

.file-name {
  width: 90%;
  background-color: #dae6ff;
  border-radius: 0.3125rem;
  transition: 0.2s ease-in-out;
}
