.loader-container {
  height: 100vh;
}

@keyframes ldio-y9fwh0owmj7 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ldio-y9fwh0owmj7 div {
  left: 86.48px;
  top: 33.120000000000005px;
  position: absolute;
  animation: ldio-y9fwh0owmj7 linear 0.7042253521126761s infinite;
  background: #2c1dff;
  width: 11.040000000000001px;
  height: 29.44px;
  border-radius: 5.0048px / 5.0048px;
  transform-origin: 5.5200000000000005px 58.88px;
}
.ldio-y9fwh0owmj7 div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.6455399061032864s;
  background: #2c1dff;
}
.ldio-y9fwh0owmj7 div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -0.5868544600938967s;
  background: #2c1dff;
}
.ldio-y9fwh0owmj7 div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.5281690140845071s;
  background: #2c1dff;
}
.ldio-y9fwh0owmj7 div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.4694835680751174s;
  background: #2c1dff;
}
.ldio-y9fwh0owmj7 div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.41079812206572774s;
  background: #2c1dff;
}
.ldio-y9fwh0owmj7 div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.35211267605633806s;
  background: #2c1dff;
}
.ldio-y9fwh0owmj7 div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.2934272300469484s;
  background: #2c1dff;
}
.ldio-y9fwh0owmj7 div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.2347417840375587s;
  background: #2c1dff;
}
.ldio-y9fwh0owmj7 div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.17605633802816903s;
  background: #2c1dff;
}
.ldio-y9fwh0owmj7 div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.11737089201877934s;
  background: #2c1dff;
}
.ldio-y9fwh0owmj7 div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.05868544600938967s;
  background: #2c1dff;
}
.ldio-y9fwh0owmj7 div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
  background: #2c1dff;
}
.loadingio-spinner-spinner-8tok92e15fd {
  width: 184px;
  height: 184px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-y9fwh0owmj7 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-y9fwh0owmj7 div {
  box-sizing: content-box;
}

/* MD LOADER */
@keyframes ldio-we0kgaonmrr {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ldio-we0kgaonmrr div {
  left: 49px;
  top: 25.5px;
  position: absolute;
  animation: ldio-we0kgaonmrr linear 0.7407407407407407s infinite;
  background: #2c1dff;
  width: 2px;
  height: 9px;
  border-radius: 0px / 0px;
  transform-origin: 1px 24.5px;
}
.ldio-we0kgaonmrr div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.7017543859649122s;
  background: #2c1dff;
}
.ldio-we0kgaonmrr div:nth-child(2) {
  transform: rotate(18.94736842105263deg);
  animation-delay: -0.6627680311890838s;
  background: #2c1dff;
}
.ldio-we0kgaonmrr div:nth-child(3) {
  transform: rotate(37.89473684210526deg);
  animation-delay: -0.6237816764132553s;
  background: #2c1dff;
}
.ldio-we0kgaonmrr div:nth-child(4) {
  transform: rotate(56.8421052631579deg);
  animation-delay: -0.5847953216374269s;
  background: #2c1dff;
}
.ldio-we0kgaonmrr div:nth-child(5) {
  transform: rotate(75.78947368421052deg);
  animation-delay: -0.5458089668615984s;
  background: #2c1dff;
}
.ldio-we0kgaonmrr div:nth-child(6) {
  transform: rotate(94.73684210526316deg);
  animation-delay: -0.50682261208577s;
  background: #2c1dff;
}
.ldio-we0kgaonmrr div:nth-child(7) {
  transform: rotate(113.6842105263158deg);
  animation-delay: -0.4678362573099415s;
  background: #2c1dff;
}
.ldio-we0kgaonmrr div:nth-child(8) {
  transform: rotate(132.6315789473684deg);
  animation-delay: -0.42884990253411304s;
  background: #2c1dff;
}
.ldio-we0kgaonmrr div:nth-child(9) {
  transform: rotate(151.57894736842104deg);
  animation-delay: -0.3898635477582846s;
  background: #2c1dff;
}
.ldio-we0kgaonmrr div:nth-child(10) {
  transform: rotate(170.52631578947367deg);
  animation-delay: -0.3508771929824561s;
  background: #2c1dff;
}
.ldio-we0kgaonmrr div:nth-child(11) {
  transform: rotate(189.47368421052633deg);
  animation-delay: -0.31189083820662766s;
  background: #2c1dff;
}
.ldio-we0kgaonmrr div:nth-child(12) {
  transform: rotate(208.42105263157896deg);
  animation-delay: -0.2729044834307992s;
  background: #2c1dff;
}
.ldio-we0kgaonmrr div:nth-child(13) {
  transform: rotate(227.3684210526316deg);
  animation-delay: -0.23391812865497075s;
  background: #2c1dff;
}
.ldio-we0kgaonmrr div:nth-child(14) {
  transform: rotate(246.31578947368422deg);
  animation-delay: -0.1949317738791423s;
  background: #2c1dff;
}
.ldio-we0kgaonmrr div:nth-child(15) {
  transform: rotate(265.2631578947368deg);
  animation-delay: -0.15594541910331383s;
  background: #2c1dff;
}
.ldio-we0kgaonmrr div:nth-child(16) {
  transform: rotate(284.2105263157895deg);
  animation-delay: -0.11695906432748537s;
  background: #2c1dff;
}
.ldio-we0kgaonmrr div:nth-child(17) {
  transform: rotate(303.1578947368421deg);
  animation-delay: -0.07797270955165692s;
  background: #2c1dff;
}
.ldio-we0kgaonmrr div:nth-child(18) {
  transform: rotate(322.10526315789474deg);
  animation-delay: -0.03898635477582846s;
  background: #2c1dff;
}
.ldio-we0kgaonmrr div:nth-child(19) {
  transform: rotate(341.05263157894734deg);
  animation-delay: 0s;
  background: #2c1dff;
}
.loadingio-spinner-spinner-zefk90pf4qdd {
  width: 91px;
  height: 91px;
  display: inline-block;
  overflow: hidden;
  margin: auto;
}
.ldio-we0kgaonmrr {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.91);
  backface-visibility: hidden;
  transform-origin: 0 0;
}
.ldio-we0kgaonmrr div {
  box-sizing: content-box;
}
