aside .nav-link > svg path,
.btn-link > svg path {
  stroke: #fff !important;
}

#set-up {
  position: fixed;
  height: 100vh;
  width: 100%;
  background-color: white;
  z-index: 99999;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.set-up-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 350px;
}

@media (min-width: 995px) {
  .navbar-vertical-aside-show-xl .navbar-vertical-aside {
    margin-left: 0 !important;
  }
  #header {
    display: none;
  }

  .navbar-vertical-aside-show-xl .main {
    padding-left: 16.25rem;
  }
}
