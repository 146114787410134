.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
  border-radius: 13px !important;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0) !important;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: none !important;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.navbar .dropdown-menu {
  border-top: none !important;
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.skeleton {
  background: #e1e1e1;
  border-radius: 4px;
  height: 50px;
  position: relative;
  overflow: hidden;
}

.skeleton::before {
  content: "";
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 100%;
  width: 150px;
  background: linear-gradient(
    to right,
    transparent 0%,
    #e8e8e8 50%,
    transparent 100%
  );
  animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

@keyframes load {
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
}

.dropdown-toggle::after {
  display: none !important;
  width: 1rem;
  height: 1rem;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 1rem 1rem;
  content: "";
  transition: 0.3s;
}

.spin-button-one::-webkit-inner-spin-button,
.spin-button-one::-webkit-outer-spin-button {
  appearance: none !important;
}

.fw-semibold {
  font-weight: inherit !important;
}

.tranx-header {
  color: #128100;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  word-wrap: break-word;
}
.trx-key {
  color: #8f9bb2;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
}

.badger {
  width: 6px;
  height: 6px;
  background: #128100;
  border-radius: 9999px;
  color: #128100;
  font-size: 14px;
  font-family: Graphik;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
  /* width: 100%; height: 100%; padding: 8px; background: #E5F6E2; border-radius: 8px; overflow: hidden; border: 0.50px #C8DEC4 solid; justify-content: center; align-items: center; gap: 8px; display: inline-flex */
}

.empty-response-pg .h3,
.empty-response-pg p {
  font-weight: 200 !important;
  font-size: 14px !important;
}

.paymentLinkDes {
  padding-top: 15px !important;
}

.form-check-input.is-invalid:checked,
.was-validated .form-check-input:invalid:checked {
  background-color: #fe9900 !important;
}

.form-check-input.is-invalid:checked + label,
.was-validated .form-check-input:invalid:checked + label {
  color: #fe9900 !important;
}

.input-fix:focus {
  overflow: hidden !important;
  font-size: 16px !important;
}

.input-focus:focus {
  /* border: ipx solid#198754 !important; */
}

#HW_badge_cont {
  position: absolute !important;
  top: -38% !important;
  color: #09a5be !important;
}

#HW_badge {
  background: #cd4b5b !important;
}

/* UNMINIFY THE CSS TO ADJUST THE MAX-WIDTH */

.overlay-effect {
  z-index: 100;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.8);
  top: 50%;
  left: 50%;
  /* position: absolute; */
  position: fixed;
  transform: translate(-50%, -50%);
  backdrop-filter: initial;
  width: 100%;
  height: 100vh;
  overflow: auto;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  transition: 0.2s ease-in-out;
  pointer-events: auto;
}

/* @keyframes slideDown {
  from {
    top: -50%;
  }
  to {
    top: 50%;
  }
} */

.overlay-child {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  /* animation-name: slideDown;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-delay: 0.1s; */
}

.kyc-page-header {
  /* border-bottom: 0.0625rem solid rgba(231, 234, 243, 0.7); */
  padding-bottom: 1rem !important;
  margin-bottom: 1.25rem !important;
}

.active-tab {
  border-bottom: 2px solid !important;
}

.kyc-card-top {
  background: #0e0b38 !important;
  padding: 20px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.kyc-save-btn {
  border: 1px solid #d0d5e9 !important;
  background-color: #fff !important;
  color: #000;
  font-weight: 500;
  cursor: pointer;
}

.kyc-save-btn:hover {
  background: #2c1dff !important;
  cursor: pointer;
  border: 1px solid #2c1dff !important;
  color: #fff !important;
}

.kyc-btn-sm {
  height: 32px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  border-radius: 8px;
  border: 1px solid #d0d5e9;
  color: #128100;
  font-size: 12px;
}

.listStyleDisc::before {
  content: "\2022";
  color: #ff9900;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: 0em;
}

.text:hover {
  color: #8c98a4 !important;
}

.cashier-button {
  border: 1px solid #d0d5e9 !important;
  border-radius: 8px !important;
  color: #ff003d !important;
  background-color: #d0d5e9;
}

.cashier-left-col {
  border-right: 1px solid #e0e4f4;
  background-color: #e0e4f4;
  min-height: 100vh;
}

.cashier-filter-select {
  border: 1px solid #d0d5e9 !important;
  border-radius: 8px !important;
  font-weight: 500;
  background: #d0d5e9;
  box-shadow: 2px 2px 0px 0px #0000000d;
}

.cashier-stats {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #8f9bb2;
}

.cashier-value {
  font-size: 28px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.01em;
  text-align: left;
}

.cashier-divider {
  border-bottom: 1px solid #cbd1ec;
  border-top: 1px solid #cbd1ec;
}

.account-details-card {
  width: auto;
  height: 315px;
  box-shadow: 4px 10px 25px 0px #00000008;
  box-shadow: 0px 4px 25px 0px #00000014;
  background-color: #ffffff;
  margin-top: 30px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 40px 60px;
}

.signage-back {
  width: 380px;
  height: 570px;
  margin-top: 30px;
  /* margin-bottom: 20px; */
  background-image: url("./assets/CASHIERCARDSBACK.svg ");
}

.signage-back-mobile {
  width: 350px;
  height: 469px;
  margin-top: 30px;
  margin-bottom: 20px;
  margin: unset;
  background-image: url("./assets/CASHIERCARDSBACK.svg ");
  background-size: contain;
}

.signage {
  width: 380px;
  max-width: 100%;
  height: 600px;
  margin-top: 30px;
  /* margin-bottom: 20px; */
  background-image: url("./assets/CASHIERCARDFRONT1.svg ");
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.signage-front {
  width: 380px;
  height: 300px;
  margin-top: 30px;
  /* margin-bottom: 20px; */
  background-image: url("./assets/cashier-back.svg");
  background-repeat: no-repeat;
}

.signage-modal {
  width: 251px;
  max-width: 100%;
  height: 450px;
  margin-top: 30px;
  /* margin-bottom: 20px; */
  background-image: url("./assets/front-modal.svg");
  background-repeat: no-repeat;
  display: flex;
  /* flex-direction: row;
  align-items: center;
  justify-content: center; */
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.signage-mobile {
  width: 342px;
  /* margin: unset; */
  background-size: auto;
  height: 600px;
  height: 560px;
  /* margin-top: 30px; */
  /* margin-bottom: 20px; */
  background-image: url("./assets/CASHIERCARDFRONT1.svg ");
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.signage-top {
  /* background: #26234c; */
  text-align: left;
  padding: 20px;
}

.signage-mid {
  padding: 10px 0px;
  /* background: #0e0b38; */
}

.signage-pay-with-transfer {
  font-size: 23px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
  /* background: #0e0b38; */
}

.signage-body {
  background: #26234c;
  padding: 30px 0px;
  /* border-bottom: 1px solid #26234c; */
}

.signage-footer {
  padding: 40px 0px;
  text-align: center;
  position: relative;
  background-image: url("./assets/Pattern1.svg");
}
.signage-smooth {
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.4em;
  text-align: center;
  color: #ffffff;
}

.pattern-two {
  position: absolute;
  top: 100%;
  left: 0%;
  width: 100%;
}

.divider {
  border-top: 1px dashed#5C5F6C;
  border-bottom: 1px dashed#5C5F6C;
}

.signage-download {
  margin-top: -50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media (max-width: 990px) {
  *,
  :before,
  :after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position: ;
    --tw-gradient-via-position: ;
    --tw-gradient-to-position: ;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia: ;
  }
  .modal.mobile-modal.show {
    --tw-translate-y: 8.5%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    min-height: 67vh;
  }
  .modal.mobile-modal {
    --tw-translate-y: 1000%;
    transition-duration: 300ms;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .mobile-mobile {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    overflow: hidden;
    position: fixed;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .mobile-mobile .modal-dialog {
    padding: 0px;
    margin: 0px;
    bottom: -55px;
    position: fixed;
    width: 100vw;
  }
  .mobile-mobile .modal-content {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 60px;
  }
  .mobile-mobile .modal-header {
    align-items: baseline;
  }
  .modal {
    --bs-modal-padding: 1rem !important;
  }
}
.w-47 {
  width: 47%;
}

.top-up-select__menu,
.top-up-select__menu-list {
  height: 180px !important;
  background-color: #fff;
  overflow: auto;
}
.modal {
  --bs-modal-zindex: 9999 !important;
}
.top-up-modal {
  width: 80% !important;
  margin: auto;
}
