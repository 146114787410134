.drop-down-wrapper {
  width: max-content;
  position: relative;
}

.drop-down-wrapper .drop-down-content {
  width: fit-content;
  position: absolute;
  right: 0%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-shadow: 15px 15px 35px 0px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  background-color: white;
  transform: scale(0);
  transition: transform 0.2s ease;
  width: max-content;
  min-width: 120px;
  z-index: 10;
}

.drop-down-content.show-drop-down {
  transform: scale(1);
}
.drop-down-content.bordered {
  border: 1px solid var(--hue-colors-hue, #cbd1ec);
}
.drop-down-content.drp-bottom-left {
  bottom: 120%;
  left: 0;
  transform-origin: bottom left;
}
.drop-down-content.drp-top-left {
  top: 120%;
  transform-origin: top right;
}
.drop-down-content .hr {
  width: 100%;
  border: 1px solid #edf2f7;
}
.drop-down-content button.btn-text,
.drop-down-content a.btn-text {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-weight: 500;
  font-size: 0.875rem;
  background: transparent;
  border: none;
  padding: 0.5rem;
}

.drop-down-content button.btn-text:hover,
.drop-down-content a.btn-text:hover {
  background: rgba(231, 234, 243, 0.4) !important;
}
