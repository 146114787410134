@font-face {
  font-family: BricolageGrotesque;
  src: url("./assets/css/fonts/BricolageGrotesque-Light.ttf");
  font-weight: 100;
}
@font-face {
  font-family: BricolageGrotesque;
  src: url("./assets/css/fonts/BricolageGrotesque-SemiBold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: BricolageGrotesque;
  src: url("./assets/css/fonts/BricolageGrotesque-SemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: BricolageGrotesque;
  src: url("./assets/css/fonts/BricolageGrotesque-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: BricolageGrotesque;
  src: url("./assets/css/fonts/BricolageGrotesque-Regular.ttf");
  font-weight: 400;
}

:root {
  --bs-body-font-family: "BricolageGrotesque", system-ui, Inter, sans-serif !important;
}

body {
  font-family: var(--bs-body-font-family);
  margin: 0;
  padding: 0;
}

input.form-control:-webkit-autofill,
input.form-control:-webkit-autofill:focus {
  background-color: #fff !important;
  color: #555555d2 !important;
}

.title {
  font-size: 12px;
  text-align: center !important;
  margin: auto !important;
}

.scan-header {
  margin: auto !important;
}

.otp-input-field {
  padding: 15px !important;
  font-size: 12px !important;
}
.nav-link:focus > svg path,
.nav-link:hover > svg path {
  color: var(--bs-nav-link-hover-color);
  stroke: var(--bs-nav-link-hover-color);
}

.nav-link > svg path,
.btn-link > svg path {
  stroke: var(--bs-nav-link-color);
}

.tooltip-inner {
  max-width: 300px !important;
  padding: 10px;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: linear-gradient(178.53deg, #2c1dff 1.25%, #261bc1 165.23%);
  --bs-btn-border-color: linear-gradient(
    178.53deg,
    #2c1dff 1.25%,
    #261bc1 165.23%
  );
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2f6ad9;
  --bs-btn-hover-border-color: #2c64cc;
  --bs-btn-focus-shadow-rgb: 85, 145, 255;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2c64cc;
  --bs-btn-active-border-color: #295ebf;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: linear-gradient(
    178.53deg,
    #2c1dff 1.25%,
    #261bc1 165.23%
  );
  --bs-btn-disabled-border-color: linear-gradient(
    178.53deg,
    #2c1dff 1.25%,
    #261bc1 165.23%
  );
}

.btn {
  background: var(--bs-btn-bg);
}

/* .dropdown-menu {
  border-top: none;
}

.navbar .dropdown-menu {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

@media (min-width: 992px) {
  .navbar-expand-lg .dropdown-menu {
    margin-top: 1rem;
  }
}

.dropdown-toggle:not(.dropdown-toggle-empty)::after {
  padding-left: 1.25rem;
} */

.driver-popover.driverjs-theme {
  background-color: #1f7aff;
  color: #000;
}

.driver-popover-footer {
  margin-top: 15px;
  text-align: right;
  zoom: 1;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: space-between; */
}

.driver-popover-navigation-btns {
  display: flex;
  /* flex-grow: 1; */
  /* justify-content: flex-end; */
  flex-direction: row;
  justify-content: end;
  align-items: center;
}

.driver-popover.driverjs-theme .driver-popover-title {
  font-size: 14px;
  font-weight: 600;
  font-family: BricolageGrotesque;
}

.driver-popover.driverjs-theme .driver-popover-title,
.driver-popover.driverjs-theme .driver-popover-description,
.driver-popover.driverjs-theme .driver-popover-progress-text {
  color: #fff;
  font-size: 12px;
  font-weight: lighter;
  font-family: BricolageGrotesque;
}

.driver-popover.driverjs-theme button {
  /* flex: 1; */
  text-align: center;
  /* background-color: #000; */
  color: #ffffff;
  /* border: 2px solid #000; */
  text-shadow: none;
  font-size: 14px;
  /* padding: 5px 8px; */
  border-radius: 6px;
}

.driver-popover.driverjs-theme button:hover {
  background-color: #000;
  color: #ffffff;
}

.driver-popover.driverjs-theme .driver-popover-navigation-btns {
  /* justify-content: space-between; */
  /* gap: 3px; */
}

.driver-popover.driverjs-theme .driver-popover-close-btn {
  color: #fff;
  border: 0px solid transparent;
  background: #1f7aff;
  font-size: 22px;
}

.driver-popover.driverjs-theme .driver-popover-close-btn:hover {
  color: #fff;
  border: 0px solid transparent;
  background: #1f7aff;
  font-size: 22px;
}

.driver-popover.driverjs-theme
  .driver-popover-arrow-side-left.driver-popover-arrow {
  border-left-color: #1f7aff;
}

.driver-popover.driverjs-theme
  .driver-popover-arrow-side-right.driver-popover-arrow {
  border-right-color: #1f7aff;
}

.driver-popover.driverjs-theme
  .driver-popover-arrow-side-top.driver-popover-arrow {
  border-top-color: #1f7aff;
}

.driver-popover.driverjs-theme
  .driver-popover-arrow-side-bottom.driver-popover-arrow {
  border-bottom-color: #1f7aff;
}

.driver-popover-arrow-side-top.driver-popover-arrow-align-start,
.driver-popover-arrow-side-bottom.driver-popover-arrow-align-start {
  left: 45%;
}

.driver-popover-next-btn {
  border: 1px solid #d0d5e9 !important;
  background: #d0d5e9 !important;
  color: #1f7aff !important;
  box-shadow: 2px 2px 0px 0px #0000000d !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  font-family: BricolageGrotesque !important;
  cursor: pointer !important;
  border-radius: 4px !important;
  padding: 10px, 12px, 10px, 12px !important;
}

.driver-popover-description {
  width: 92% !important;
}

.driver-popover-prev-btn {
  border: 1px solid #d0d5e9 !important;
  background: #1f7aff !important;
  font-family: BricolageGrotesque !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  font-family: BricolageGrotesque !important;
  color: #fff !important;
  box-shadow: 2px 2px 0px 0px #0000000d !important;
  cursor: pointer !important;
  border-radius: 4px !important;
  padding: 10px, 12px, 10px, 12px !important;
  margin-right: 8px !important;
}

.modal-header-bg {
  background-image: url("./assets/walkthrough.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.phone-number-field {
  font-family: BricolageGrotesque !important;
}

#HW_badge_cont {
  position: absolute !important;
  top: 5.5% !important;
  color: #09a5be !important;
}

span#HW_badge[data-count-unseen=""] {
  display: none !important;
}

.HW_frame_cont {
  pointer-events: none;
  border-radius: 4px;
  box-shadow: 0 0 1px rgba(99, 114, 130, 0.32),
    0 8px 16px rgba(27, 39, 51, 0.08);
  background: #fff;
  border: none;
  position: fixed;
  top: -900em;
  z-index: 2147483647;
  width: 340px;
  height: 0;
  opacity: 0;
  will-change: height, margin-top, opacity;
  margin-top: -10px;
  transition: margin-top 0.15s ease-out, opacity 0.1s ease-out;
  overflow: hidden;
  left: calc(100% - 120px);
}

.hor-rule {
  border: 1px solid #1f7aff;
  margin-top: 50px;
  margin-bottom: 50px;
}

.hor-rule-success {
  border: 1px solid #169e00;
}

.animated-row {
  transition: all ease-in-out 0.15s;
}

.bal-sheet {
  background: #fafafd;
}

.total-text {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
}

.total-value {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  text-align: right;
}

.item-container {
  border-bottom: 0.5px solid #8f9bb2;
  border-right: 0.5px solid #8f9bb2;
  border-left: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 5px;
}

.complete-kyb {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.card-kyb {
  border: 1px solid #d5b5ff !important;
  background: #d5b5ff40 !important;
  /* background: linear-gradient(0deg, #f5edff, #f5edff),
    linear-gradient(0deg, #d5b5ff, #d5b5ff) !important; */
}

.card_bg_img {
  background-image: url("./assets/Getstartedimg.svg") !important;
  background-repeat: no-repeat !important;
  background-position: bottom right !important;
}

.grid-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #000000;
}

.card-left {
  border: 1px solid #eaedf7;
  box-shadow: 2px 2px 0px 0px #0000000d;
}

.icon-bg {
  background: #e9f2ff;
}

.tour-btn {
  height: 32px;
  border-radius: 8px;
  text-align: center;
  border: 1px solid #d0d5e9;
  box-shadow: 2px 2px 0px 0px #0000000d;
  background: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #000;
}

.c-override {
  padding: 10px !important;
}

.support-links {
  border: 1px solid #d0d5e9;
  box-shadow: 2px 2px 0px 0px #0000000d;
  background: #fff;
  border-radius: 8px;
  height: 32px;
  cursor: pointer;
  width: auto;
}

.footer-get-started {
  border-top: 1px solid #e0e4f4;
  background: #ffffff;
  color: #8f9bb2;
  box-shadow: 0px -1px 20px rgba(0, 0, 0, 0.04);
}

.get-started-header {
  border-bottom: 1px solid #cbd1ec;
}

.get-started-body {
  position: relative;
}

.pt-3.pb-4.text-center.mx-auto {
  height: 7rem;
}

.upgrade-btn {
  background: #fff;
  border-radius: 8px;
  height: 32px;
  cursor: pointer;
  width: auto;
}

.mobile-position.show {
  left: -200% !important;
}

.alert-no-radius {
  border-radius: 0px !important;
  text-align: center !important;
}

.subtitle-box {
  border: 1px solid #d0d5e9;
  box-shadow: 2px 2px 8px 0px #0000000d;
  border-radius: 4px;
}

.create-virtual-acc {
  font-weight: 500;
  font-size: 14px;
}

.adddd {
  box-shadow: 2px 2px 0px 0px #0000000d;
  border: 1px solid #d0d5e9;
  color: #2c1dff;
  border-radius: 8px;
  font-weight: 600;
}

.icon.js-form-search {
  padding-left: 35px !important;
  padding-right: 20px;
  padding: 7px 20px 7px 10px;
  background: url("../src/assets/sarchglass.svg") no-repeat right;
  background-size: 20px;
  background-position-x: 3%;
  outline: none;
  box-shadow: none;
}

.icon-btn {
  box-shadow: 0px 2px 12px 0px #0000001a;
  border-radius: 32px !important;
}

.offcanvas.show:not(.hiding),
.offcanvas.showing {
  width: 80% !important;
}

.offcanvas {
  max-width: 80% !important;
}

.transferred-to {
  font-size: 8px;
  font-weight: normal;
  color: #788088;
  letter-spacing: 4px;
}

.bank_name::after {
  content: "\2022";
  padding-right: 10px;
  padding-left: 10px;
  font-size: 15px;
}
