/* Tabs.css */
.tabs .nav-link {
  position: relative;
  text-decoration: none;
  color: #000; /* Default tab text color */
  padding: 8px 16px;
}

.tabs .nav-link.active {
  color: #2C1DFF; /* Active tab text color (orange) */
}

.tabs .nav-link::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px; /* Underline height */
  background-color: #2C1DFF; /* Underline color (orange) */
  transform: scaleX(0); /* Initially no underline */
  transition: transform 0.2s ease-in-out;
}

.tabs .nav-link.active::after {
  transform: scaleX(1); /* Show underline for the active tab */
}
