.steps {
    list-style: none;
    margin: 0;
    padding: 0;
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 4rem;
}

.steps>.step {
    position: relative;
    display: table-cell;
    text-align: center;
    font-size: 0.875rem;
}

.steps>.step:before {
    content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzgiIGhlaWdodD0iMzgiIHZpZXdCb3g9IjAgMCAzOCAzOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTkiIGN5PSIxOSIgcj0iMTkiIGZpbGw9IiNDOERFQzQiLz4KPGNpcmNsZSBjeD0iMTkiIGN5PSIxOSIgcj0iMTIiIGZpbGw9IiMxMjgxMDAiLz4KPHBhdGggZD0iTTI1IDE0LjVMMTYuNzUgMjIuNzVMMTMgMTkiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPg==");
    display: block;
    margin: 0 auto;
    text-align: center;
    line-height: 1.9rem;
    position: relative;
    z-index: 1;
    font-weight: 700;
    font-size: 1rem;
}

.steps>.step.unsuccessful:before {
    content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzgiIGhlaWdodD0iMzgiIHZpZXdCb3g9IjAgMCAzOCAzOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTkiIGN5PSIxOSIgcj0iMTkiIGZpbGw9IiNGRkNDRDgiLz4KPGNpcmNsZSBjeD0iMTkiIGN5PSIxOSIgcj0iMTIiIGZpbGw9IiNGRjAwM0QiLz4KPHBhdGggZD0iTTIzLjUgMTQuNUwxNC41IDIzLjVNMTQuNSAxNC41TDIzLjUgMjMuNSIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
    display: block;
    margin: 0 auto;
    text-align: center;
    line-height: 1.9rem;
    position: relative;
    z-index: 1;
    font-weight: 700;
    font-size: 1rem;
}

.steps>.step.failed:before {
    content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzgiIGhlaWdodD0iMzgiIHZpZXdCb3g9IjAgMCAzOCAzOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTkiIGN5PSIxOSIgcj0iMTkiIGZpbGw9IiNGRkNDRDgiLz4KPGNpcmNsZSBjeD0iMTkiIGN5PSIxOSIgcj0iMTIiIGZpbGw9IiNGRjAwM0QiLz4KPHBhdGggZD0iTTIzLjUgMTQuNUwxNC41IDIzLjVNMTQuNSAxNC41TDIzLjUgMjMuNSIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
    display: block;
    margin: 0 auto;
    text-align: center;
    line-height: 1.9rem;
    position: relative;
    z-index: 1;
    font-weight: 700;
    font-size: 1rem;
}

.steps>.step[aria-label="unsuccessful"]:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 0.125rem;
    top: 1rem;
    left: 50%;
    border: 1px dotted #FF003D;
}

.steps>.step[aria-label="failed"]:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 0.125rem;
    top: 1rem;
    left: 50%;
    border: 1px dotted #FF003D;
}

.steps>.step:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 0.125rem;
    top: 1rem;
    left: 50%;
    border: 1px dotted #128100;
}

.steps>.step:last-child:after {
    display: none;
}

.step p {
    margin-top: 5px;
    margin-bottom: 3px;
    font-size: 15px;
    font-weight: 700;
    color: #0E0B38;
}

.step span {
    font-size: 11px;
    margin-bottom: 8px;
}

.--timeline {
    background-color: #F0F1F9 !important;
}

@media (min-width: 760px) {
    .step p {
        font-size: 17px;
    }
    .step span {
        font-size: 13px;
    }
}